
<template>
  <el-button plain size="small" @click="getEndpoints()">Afficher</el-button>

  <div class="demo-image__error">
    <div class="row block ml-5">
      <div align="left">
        <el-space wrap>
          <h3> Sim </h3>
          <hr style="
              width: 300px;
              margin-left: 10px;
              size: 5px;
              border-color: lightgray;
            " />

          <!-- <edit-device :sim="sim.data"></edit-device> -->
        </el-space>
      </div>
      <el-descriptions class="mt-0" title="" :column="2">
        <el-descriptions-item label="Appareil assigné"></el-descriptions-item>
        <el-descriptions-item label="SIM ID">

        </el-descriptions-item>
        <el-descriptions-item label="ICCID">
        </el-descriptions-item>
        <el-descriptions-item label="MSISDN">
        </el-descriptions-item>
        <el-descriptions-item label="IMSI ">
        </el-descriptions-item>
        <el-descriptions-item label="Fournisseur">
        </el-descriptions-item>
        <el-descriptions-item label="Mémoire ">
        </el-descriptions-item>
        <el-descriptions-item label="Produit ">
        </el-descriptions-item>
        <el-descriptions-item label="Modele"> </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EmnifyAppToken } from '../../config/config'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      dialogTableDetails: false,
      searchimei: '',
      simm: []

    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      token: 'auth/token',
      devices: 'devices/devices',
      device: 'devices/device',
      metadata: 'devices/metadata',
      member: 'auth/member',
      org: 'organisations/organisation',
      eminToken: 'emnify/authToken',
      sim: 'emnify/sim',
      sims: 'emnify/sims',
      endpoints: 'emnify/endpoints',
    }),
  },
  props: ['index', 'row'],
  mounted() {
    this.getEmnifyToken()
  },
  methods: {
    ...mapActions({
      getauthapi: 'emnify/getauthtoken',
      getsims: 'emnify/getSims',
      getEndPoints: 'emnify/getEndpoints',
    }),

    formatDate(row, column) {
      var date = row[column.property]
      if (date == undefined) {
        return ''
      }
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    },

    getEndpoints() {
      const emei = this.device.uniqueId
      const lastemei = emei.slice(-7)

      let payload = {
        auth_token: this.eminToken,
        params: {
          q: 'name:' + lastemei,
        },
      }
      this.$store
        .dispatch('emnify/getEndpoints', payload)

        .then((response) => (this.simm = response))
    },

    getEmnifyToken() {
      var data = {
        application_token: EmnifyAppToken,
      }
      this.getauthapi(data)
    },
  },
}
</script>

<style>
.button-yoda:hover {
  background-color: #ffef9f;
  border: #ff9b2e;
  color: #ff9b2e;
}

.button-yoda-user:hover {
  background-color: #c19ee0;
  border: white;
  color: white;
}

form label {
  font-weight: bold;
}
</style>